/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useEffect, useState } from 'react';
import {
  HvTableContainer,
  HvTable,
  HvTableHead,
  HvTableRow,
  HvTableHeader,
  HvTableBody,
  HvEmptyState,
  HvTableCell,
  HvLoading,
  HvTooltip
} from '@hitachivantara/uikit-react-core';
import './LifecycleDetails.scss';
import { Ban } from '@hitachivantara/uikit-react-icons';
import { getLifecycleDetails } from '../../service/lifecycleDetailsService';
import { ColorBox } from './ColorBox';
import styled from '@emotion/styled';
import { Legend } from './Legend';
import RecommendationDialog from './RecommendationDialog';

interface LifecycleDetailsResponse {
  productFamily: string;
  productSeries: string;
  productName: string;
  productVersion: string;
  erpArticleNo: string;
  lifeCycleActiveDate: string;
  lifeCycleClassicDate: string;
  lifeCycleLimitedDate: string;
  lifeCycleObsoleteDate: string;
  active: {
    startYear: number;
    endYear: number;
  };
  classic: {
    startYear: number;
    endYear: number;
  };
  limited: {
    startYear: number;
    endYear: number;
  };
  obsolete: {
    startYear: number;
    endYear: number;
  };
}

interface ProductFilters {
  productSeriesSelected: string[] | [];
  productNamesSelected: string[] | [];
  productVersionsSelected: string[] | [];
}

const LifecycleDetails = ({
  productSeriesSelected,
  productNamesSelected,
  productVersionsSelected
}: ProductFilters) => {
  const [startYear, setStartYear] = useState<number>(0);
  const [endYear, setEndYear] = useState<number>(0);
  const [data, setData] = useState<LifecycleDetailsResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recommendationDialogOpen, setRecommendationDialogOpen] =
    useState<boolean>(false);
  const [productNameForRecommendation, setProductNameForRecommendation] =
    useState<string>('');

  const fetchLifecycleDetails = useCallback(async () => {
    setIsLoading(true);
    const series = productSeriesSelected;
    const names = productNamesSelected;
    const versions = productVersionsSelected;

    const response = await getLifecycleDetails(series, names, versions);

    if (response) {
      const responseData = response?.data?.data;
      setData(responseData?.lifecycleDetailsResponse);
      setStartYear(responseData?.yearRange?.startYear);
      setEndYear(responseData?.yearRange?.endYear);
    }
    setIsLoading(false);
  }, [productSeriesSelected, productNamesSelected, productVersionsSelected]);

  useEffect(() => {
    fetchLifecycleDetails();
  }, [fetchLifecycleDetails]);

  const GetYearColumns = () => {
    const columnNames: {
      headerText: string;
      accessor: string;
      width: string;
    }[] = [];
    columnNames.push({
      headerText: 'Product',
      accessor: 'productName',
      width: '30px'
    });
    columnNames.push({
      headerText: 'Version',
      accessor: 'productVersion',
      width: '30px'
    });
    columnNames.push({
      headerText: 'Article No',
      accessor: 'erpArticleNo',
      width: '40px'
    });

    for (let i = startYear; i <= endYear; i++) {
      columnNames.push({
        headerText: i.toString(),
        accessor: i.toString(),
        width: '15px'
      });
    }

    return columnNames;
  };

  const columns = GetYearColumns();

  const generateColumnValues = (el: LifecycleDetailsResponse) => {
    const rows: (string | React.JSX.Element)[] = [];
    type statusValues = 'active' | 'classic' | 'limited' | 'obsolete';
    const statuses: {
      start: number;
      end: number;
      status: statusValues;
    }[] = [
      {
        start: el.active.startYear,
        end: el.classic.startYear,
        status: 'active'
      },
      {
        start: el.classic.startYear,
        end: el.limited.startYear,
        status: 'classic'
      },
      {
        start: el.limited.startYear,
        end: el.obsolete.startYear,
        status: 'limited'
      },
      {
        start: el.obsolete.startYear,
        end: el.obsolete.endYear + 1,
        status: 'obsolete'
      }
    ];

    for (let i = startYear; i <= endYear; i++) {
      if (el.lifeCycleActiveDate === null) {
        rows.push(
          <HvTableCell
            key={i}
            className={`status-cell ${i % 5 === 0 ? 'grey-background' : ''}`}
            data-testid='status-cell'
            onClick={handleClick(el.productName)}
          ></HvTableCell>
        );
      } else {
        const status = statuses.find((s) => s.start <= i && i < s.end)?.status;
        rows.push(
          <HvTableCell
            key={i}
            className={`status-cell ${i % 5 === 0 ? 'grey-background' : ''}`}
            data-testid='status-cell'
            onClick={handleClick(el.productName)}
          >
            {status ? (
              <ColorBox
                status={status}
                isLastOfKind={el[status].endYear === i}
                isFristOfKind={el[status].startYear === i}
              />
            ) : null}
          </HvTableCell>
        );
      }
    }
    return rows;
  };

  const productNameList: string[] = [];
  const productVersionList: string[] = [];

  const renderProductName = (productName: string) => {
    if (productNameList.includes(productName)) {
      return null;
    }
    productNameList.push(productName);
    return (
      <HvTableCell
        key={productName}
        className='stickyPosition lifecycle-details-productName'
        align='center'
        rowSpan={data.filter((item) => item.productName === productName).length}
      >
        {productName}
      </HvTableCell>
    );
  };

  const renderProductVersion = (
    productVersion: string,
    productName: string
  ) => {
    if (productVersionList.includes(productVersion)) {
      return null;
    }
    productVersionList.push(productVersion);
    return (
      <HvTooltip
        title={productVersion}
        placement='right'
        className='tooltip-outline'
      >
        <HvTableCell
          key={productVersion}
          className='stickyPosition lifecycle-details-productVersion'
          rowSpan={
            data.filter((item) => item.productVersion === productVersion).length
          }
          align='center'
          onClick={handleClick(productName)}
        >
          {productVersion}
        </HvTableCell>
      </HvTooltip>
    );
  };

  const StyledResponsiveTableContainer = styled(HvTableContainer)({
    minWidth: 200,
    maxHeight: 480,
    overflow: 'auto',
    marginTop: 10,
    border: '1px solid #e0e0e0',
    borderRadius: 2
  });

  const handleProductSelection = (productNameSelected: string) => {
    setRecommendationDialogOpen(true);
    setProductNameForRecommendation(productNameSelected);
  };

  const handleClick = (productNameClicked: string) => {
    return () => handleProductSelection(productNameClicked);
  };

  const handleDialogClose = useCallback(() => {
    setRecommendationDialogOpen(false);
  }, []);

  return (
    <>
      {data && data.length > 0 && <Legend />}
      <StyledResponsiveTableContainer>
        <HvTable
          data-testid='lifecycle-details-table'
          id='lifecycle-details-table'
          className='lifecycle-details-table'
        >
          <HvTableHead className='lifecycle-details-tableHeader'>
            <HvTableRow>
              {columns.map((el) => (
                <HvTableHeader
                  className={
                    el.width === '15px'
                      ? 'status-cell-header'
                      : 'stickyPosition status-cell-header lifecycle-details-' +
                        el.accessor
                  }
                  align='center'
                  key={el.headerText}
                  id='tableHeader'
                >
                  {el.headerText}
                </HvTableHeader>
              ))}
            </HvTableRow>
          </HvTableHead>
          <HvTableBody>
            {isLoading && data && (
              <HvTableRow
                key='Loading'
                hover
                striped
              >
                <HvTableCell colSpan={8}>
                  <HvLoading
                    id='micro-scada-table-data-loading'
                    data-testid='micro-scada-table-data-loading'
                    small
                    label='Fetching Lifecycle Data'
                  />
                </HvTableCell>
              </HvTableRow>
            )}
            {data?.length > 0 &&
              !isLoading &&
              data.map((dt: LifecycleDetailsResponse) => (
                <>
                  <HvTableRow
                    key={dt.productName}
                    hover
                    striped
                  >
                    {renderProductName(dt.productName)}
                    {renderProductVersion(dt.productVersion, dt.productName)}
                    <HvTooltip
                      title={<div>{dt.erpArticleNo}</div>}
                      placement='right'
                      className='tooltip'
                    >
                      <HvTableCell
                        key={dt.erpArticleNo}
                        className='stickyPosition lifecycle-details-erpArticleNo'
                        data-testid='status-cell-header'
                        align='center'
                        onClick={handleClick(dt.productName)}
                      >
                        {dt.erpArticleNo}
                      </HvTableCell>
                    </HvTooltip>
                    {generateColumnValues(dt)}
                  </HvTableRow>
                </>
              ))}
            {!isLoading && data === undefined && (
              <HvTableRow>
                <HvTableCell colSpan={100}>
                  <HvEmptyState
                    message='No data to display'
                    icon={<Ban />}
                  />
                </HvTableCell>
              </HvTableRow>
            )}
          </HvTableBody>
          {recommendationDialogOpen ? (
            <RecommendationDialog
              open={recommendationDialogOpen}
              productName={productNameForRecommendation}
              onClose={handleDialogClose}
            />
          ) : (
            ''
          )}
        </HvTable>
      </StyledResponsiveTableContainer>
    </>
  );
};

export default LifecycleDetails;
