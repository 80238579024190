/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom';
import AssetInventory from '../pages/AssetInventory/AssetInventory';
import Lifecycle from '../pages/Lifecycle/Lifecycle';
import { CoreRoutes } from '../const/appConstants';
import AuthPage from '../pages/AuthPage/AuthPage';
import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import {
  HvGrid,
  HvHeader,
  HvHeaderActions,
  HvHeaderBrand,
  HvPanel
} from '@hitachivantara/uikit-react-core';
import UserInformation from '../Components/UserInformation/UserInformation';
import AppNavigation from '../Components/AppNavigation/AppNavigation';
import hitachiLogo from '../Components/assets/hitachi-energy.svg';
import './AppRoutes.scss';

const AppRoutes = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const[isAppNavCollapse, setisAppNavCollapse] = useState(true);

  const handleAppNavCollapseChange = useCallback((value:boolean)=>{
    setisAppNavCollapse(value);
  },[]);

  useEffect(() => {
    const handleAuth = () => {
      if (location.pathname === CoreRoutes.login) {
        navigate(CoreRoutes.login);
      } else {
        if (!isAuthenticated) {
          if (
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect
          ) {
            console.log('Checking login status...');
          } else {
            navigate(CoreRoutes.homeAuth);
          }
        }
      }
    };
    handleAuth();
  }, [isAuthenticated, inProgress, navigate, location.pathname]);

  return (
    <>
      <UnauthenticatedTemplate>
        <Routes>
          <Route
            path={CoreRoutes.login}
            element={<Login />}
          />
          <Route
            path={CoreRoutes.homeAuth}
            element={<AuthPage />}
          />
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <>
          <HvHeader position='sticky'>
            <HvHeaderBrand
              id='homepage-header'
              logo={
                <img
                  className='logo'
                  src={hitachiLogo}
                  alt='Hitachi logo'
                />
              }
              name='Asset Health Guardian'
              data-testid='app-header'
            />
            <HvHeaderActions>
              <UserInformation />
            </HvHeaderActions>
          </HvHeader>

          <HvGrid
            container
            direction='row'
            className='full-height'
          >
            <HvGrid
              item
              xs={isAppNavCollapse ? 2 : 1}
            >
              <AppNavigation handleAppNavCollapseChange={handleAppNavCollapseChange}/>
            </HvGrid>
            <HvGrid
              item
              xs={isAppNavCollapse ? 10 : 11}
              className='main-screen'
            >
              <HvPanel>
                <HvGrid
                  container
                  className='container'
                >
                  <HvGrid
                    item
                    className='mainScreenPanel'
                  >
                    <Routes>
                      <Route
                        path={CoreRoutes.home}
                        element={<Dashboard />}
                      />
                      <Route
                        path={CoreRoutes.homeAuth}
                        element={<AuthPage />}
                      />

                      <Route
                        path={CoreRoutes.asset}
                        element={<AssetInventory />}
                      />
                      <Route
                        path={CoreRoutes.lifecycle}
                        element={<Lifecycle />}
                      />
                      <Route
                        path={CoreRoutes.logout}
                        element={<Logout />}
                      />
                      <Route
                        path='/login'
                        element={
                          <Navigate
                            to='/'
                            replace
                          />
                        }
                      />
                      <Route
                        path='*'
                        element='Page not found'
                      />
                    </Routes>
                  </HvGrid>
                </HvGrid>
              </HvPanel>
            </HvGrid>
          </HvGrid>
        </>
      </AuthenticatedTemplate>
    </>
  );
};

export default AppRoutes;
