/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useState, useEffect } from 'react';
import {
  HvVerticalNavigation,
  HvVerticalNavigationHeader,
  HvVerticalNavigationTree
} from '@hitachivantara/uikit-react-core';
import { Components, BarChart } from '@hitachivantara/uikit-react-icons';
import './AppNavigation.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export interface AppNavigationProps {
  handleAppNavCollapseChange: (value: boolean) => void;
}

const AppNavigation = ({ handleAppNavCollapseChange }: AppNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [show, setShow] = useState(true);
  useEffect(() => {
    setSelectedPage(pathname.slice(1));
  }, [pathname]);

  const renderPage = useCallback(
    (_event: React.SyntheticEvent, page: { id: string }) => {
      const { id } = page;
      setSelectedPage(id);
      switch (id) {
        case 'asset':
          navigate(`/${id}?type=microscada`);
          break;
        case 'lifecycle':
          navigate(`/${id}`);
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const handleCollapse = useCallback(() => {
    const newShowState = !show;
    setShow(newShowState);
    handleAppNavCollapseChange(newShowState);
  }, [handleAppNavCollapseChange, show]);

  return (
    <div className='navbar'>
      <HvVerticalNavigation
        useIcons
        open={show}
        data-testid='vertical-navigation'
      >
        <HvVerticalNavigationHeader
          title='Menu'
          onCollapseButtonClick={handleCollapse}
          collapseButtonProps={{
            'aria-label': 'collapseButton',
            'aria-expanded': show
          }}
        />
        {show && (
          <span style={{ fontWeight: '600', fontSize: '14px' }}>
            Guardian Analytics
          </span>
        )}
        <HvVerticalNavigationTree
          data-testid='vertical-navigation-tree'
          collapsible
          data={[
            {
              icon: <Components />,
              id: 'asset',
              label: 'Assets'
            },
            {
              icon: <BarChart style={{ rotate: '90Deg' }} />,
              id: 'lifecycle',
              label: 'Lifecycle'
            }
          ]}
          defaultExpanded
          onChange={renderPage}
          selected={selectedPage}
          style={{ paddingLeft: '5px' }}
        />
      </HvVerticalNavigation>
    </div>
  );
};
export default AppNavigation;
