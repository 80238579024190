/// Copyright 2024 Hitachi Energy. All rights reserved.

import { HvBox } from '@hitachivantara/uikit-react-core';
import React from 'react';
import './ColorBox.scss';

const colorSchema = {
  active: '#99AC3C',
  classic: '#FFD86C',
  limited: '#BA4E56',
  obsolete: '#5373C2'
};

type status = 'active' | 'classic' | 'limited' | 'obsolete';

interface TColorBox {
  status: status;
  isLastOfKind?: boolean;
  isFristOfKind?: boolean;
}

export const ColorBox = (props: TColorBox) => {
  const { status, isLastOfKind, isFristOfKind } = props;
  const leftRadius = isFristOfKind ? '2px' : '0px';
  const rightRadius = isLastOfKind ? '2px' : '0px';
  return (
    <HvBox
      role='box'
      className={`color-box ${isLastOfKind ? 'isLastOfKind' : 'normal'}`}
      sx={{
        backgroundColor: colorSchema[status],
        height: '20px',
        borderRadius: `${leftRadius} ${rightRadius} ${rightRadius} ${leftRadius}`
      }}
    ></HvBox>
  );
};
